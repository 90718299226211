<template>
  <div id="app">
    <div v-if="logged" >
      <Search v-show="searching == true" @selected="selectVeicle" />
      <Veicle v-if="searching == false" @back="searching = true" :selected="selectedVeicle" /> 
    </div>
    <Login @login="authenticated" v-else />
    <!-- <button class="chat-button" @click="toggleModal">
      Assistenza
    </button> -->
    <div v-if="showModal" class="modal-overlay">
      <div class="modal-content">
        <h3>Richiedi Assistenza</h3>
        <p>Inserisci il tuo nome e il numero di telefono per essere contattato su WhatsApp dall'assistenza Rovertex.</p>
        <form @submit.prevent="requestAssistance">
          <div>
            <label for="name">Nome:</label>
            <input type="text" id="name" v-model="assistanceData.name" required />
          </div>
          <div>
            <label for="phone">Telefono <small>(senza prefisso internazionale ed. 3481234567)</small>:</label>
            <input type="text" id="phone" v-model="assistanceData.phone" required />
          </div>
          <div class="button-group">
            <button type="submit" class="close-button">Invia richiesta</button>
            <button type="button" class="close-button" @click="toggleModal">Chiudi</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Login from './components/Login.vue'
import Search from './components/Search.vue'
import Veicle from './components/Veicle.vue'

export default {
  name: 'app',
  components: {
    Login,
    Search,
    Veicle
  },
  data: function() {
    return {
      logged: false, //Change it to false;
      searching: true,
      selectedVeicle: null,
      showModal: false,
      assistanceData: {
        name: '',
        phone: ''
      }
    }
  },
  mounted() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if(urlParams.has('token') && urlParams.get('token') === 'scar_945') {
      this.authenticated();
    }
  },
  methods: {
    authenticated() {
      this.logged = true;
      this.$crisp.load('72ff0c2c-3d16-4b55-8712-ca089df48a21')
    },
    selectVeicle(payload) {
      this.selectedVeicle = payload;
      this.searching = false;
    },
    toggleModal() {
      this.showModal = !this.showModal;
    },
    async requestAssistance() {
      const { name, phone } = this.assistanceData;

      // Validazione dei campi
      if (!name.trim()) {
        alert('Per favore inserisci il tuo nome.');
        return;
      }
      if (!phone.trim() || !/^\d+$/.test(phone)) {
        alert('Per favore inserisci un numero di telefono valido.');
        return;
      }

      // Chiamata a Spoki
      try {
        const response = await this.$axios.post(`https://app.spoki.it/wh/ap/4ace77e7-e0c2-4804-befd-4813093e17c6/`, {
          secret: "45bea2181eb043f0b4ae684e4d91b412",
          phone,
          first_name: name,
          last_name: "",
          email: "",
          custom_fields: {}
        });
        console.log("Richiesta inviata con successo:", response.data);
        alert("Richiesta di assistenza inviata con successo!");
        this.toggleModal();
      } catch (error) {
        console.error("Errore durante l'invio della richiesta:", error);
        alert("Si è verificato un errore. Per favore riprova.");
      }
    }
  }
}
</script>

<style lang="scss">
  @import url('https://fonts.googleapis.com/css?family=Teko&display=swap&subset=latin-ext');
  body {
    font-family: 'Teko', sans-serif;
    font-size: 1.15rem;
    background-image: url("/assets/bg.png");
    background-repeat: repeat;
  }
  .h5, h5 {
    font-size: 1.45rem;
  }

  .item-rounded {
    border-radius: 1rem;
  }

  div.logo-container {
    margin-top: 50px;

    img.logo {
        width: 250px;
    }
  }

  .item-semi-rounded {
      border-radius: 5px;
  }

  tr th {
      color: #c3c3c3;
      font-weight: 400
  }

  .btn-scar {
    background-color: #d40074;
    color:white;

    &:hover {
      color: white;
    }

    &:focus {
      outline: 0;
      -webkit-box-shadow: 0 0 0 0.2rem rgba(212,0,116,.25);
      box-shadow: 0 0 0 0.2rem rgba(212,0,116,.25);
    }
  }
  .button-group {
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }
  .chat-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #d40074;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 50px;
    cursor: pointer;
    font-size: 16px;
    z-index: 1000;
  }

  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2000;
  }

  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    max-width: 400px;
    width: 90%;
  }

  .modal-content h3 {
    margin-top: 0;
  }

  .modal-content form {
    margin-top: 20px;
  }

  .modal-content form div {
    margin-bottom: 15px;
    text-align: left;
  }

  .modal-content form label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }

  .modal-content form input {
    width: calc(100% - 20px);
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }

  .close-button {
    margin-top: 10px;
    background: #d40074;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
  }
</style>
